@import "../../../public/mixins/mixins.scss";

.doctorsCarouselWrapper {
  background: var(--bg45, linear-gradient(180deg, #e3f8ff 0%, #fbfdfe 100%));

  .doctorsContainer {
    @include flexContainer(column, unset, center, 40px);
    max-width: 1440px;
    margin: auto;
    padding: 55px 50px;
    @include mobile() {
      padding: 20px 50px;
      row-gap: 20px;
    }
    .doctorsHeading {
      text-align: center;
      @include font(32px, 300, #4a536a, 1.28px);
      @include mobile() {
        font-size: 20px;
      }
      .highlight {
        @include font(32px, 700, #006daf, 1.28px);
        @include mobile() {
          font-size: 20px;
        }
      }
    }
    .viewMoreBtn {
      @include buttonType1(10px 30px);
    }
  }
}
